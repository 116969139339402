.mainContainer {
  /* width: 100%; */
  padding: 20px;
}
.logoContainer {
  display: flex;
  padding-top: 20px;
  justify-content: center;
}
.errors {
  color: red;
  font-size: 14px;
  font-style: italic;
  text-align: left;
  margin-top: -12px;
  margin-bottom: 15px;
}
.inputSectionContainer {
    /* border: 1px solid grey; */
    width: 40%;
    margin: 60px auto;
background-color: #fff;
    padding: 26px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.headImage {
  text-align: center;
}
.headingTextFirst {
  font-size: 24px;
  font-weight: 700;
  padding: 15px 0 12px 0;
}
.headingTextSecond {
  font-size: 17px;
  font-weight: 500;
}
.headingTextThird {
  font-size: 16px;
  padding: 35px 0 15px 0;
  color: gray;
}
.progressBar {
  margin: auto;
  width: 60%;
}
.inputContainer {
  width: 80%;
  /* margin: 3rem auto; */
  margin: 45px auto 30px;
}
.btnNext {
  width: 50%;
  margin: 30px auto 10px;
  
}
.sasOneText {
  padding: 50px 0px 0px;
}
.loginButton{
  color: #ef5d25;
  cursor: pointer;
}
.loginButton:hover{
  color:#1778f2 ;

}
@media screen and (max-width: 1023px) {
  .inputSectionContainer {
    /* border: 1px solid grey; */
    width: 100%;
    /* margin: 4rem auto; */
    padding: 0px;
    box-shadow: none;
  }
  .progressBar {
   
    width: 75%;
  }
  .btnNext {
    width: 75%;
  }
  .inputContainer {
    width: 98%;
    /* margin: 3rem auto 2rem; */
  }
}
