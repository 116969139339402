.cardBox{
    background-color: white;
    border-radius: 10px;
    border: 1px solid #dddddd;
    width: 280px;
    text-decoration: none;
    color: black;
    margin: 10px;
}
.cardBoxFeed{
    background-color: white;
    border-radius: 10px;
    border: 1px solid #dddddd;
    width: 280px;
    text-decoration: none;
    color: black;
    margin: 10px;
}
.cardBoxFeed:hover{
    border: 1px solid #ef5122;
}


.heading{
    font-weight: bold;
    margin: 0 0 15px 10px;
}
.date{
    color: #73777B;
    font-size: 16px;
    margin-bottom: 10px;
    }
.userIcon {
    height: 40px;
    width: 40px;
    border-radius: 50px;
    margin: 3px 10px 0 2px;
  }
.headerOuterBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 14px 0px 14px
}

.headerFirstBox{
    display: flex;
    align-items: center;
}

.userTitle {
    font-weight: 600;
    width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
.postHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.postHeaderUsername{
    display: flex;
    align-items: center;
}
.userIcon{
    height: 35px;
    width: 35px;
    margin: 3px 10px 5px 2px;
}

.profImgBox{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin: 3px 10px 5px 2px;
}

.line{
    border-bottom: 1px solid #bbb;
    margin-bottom: 2px;
}

.lineBottom{
    border-top: 1px solid #bbb;
}

.postImg{
    text-align: center;
    height: 250px;
    padding: 0px 15px 0px 15px;
    /* width: 250px; */
}
.postImginner{
    /* max-width: 100%;
    max-height: 100%; */
    object-fit: contain;
}

.postImgFirst{
    object-fit: cover;
    text-align: center;
    width: 90%;
    padding: 0px 12px;
    height: 200px;
}
.postImgFirst:hover{
    object-fit: contain;
}

.likesCountBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    color: #423f3eea;
}

.heart{
    margin-right: 18px;
    position: relative;
}

.like{
    position: absolute;
    padding-left: 12px;
}

.iconLikesBox{
    display: flex;
    align-items: center;
}

.bottomTextBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    color: #423f3eea;
}

.bottomIconBox{
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* .iconBottom{
    margin-right: 8px;
} */

.userSubTitle{
    color: #73777B;
}

.caption{
    white-space: normal;
    text-align: left;
    margin: 0px 10px 10px 10px;
    width: 250px;
}