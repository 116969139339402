.mainContainer {
    /* width: 100%; */
    padding: 20px;
  }
  .logoContainer {
    display: flex;
    padding-top: 20px;
    justify-content: center;
  }
  .inputSectionContainer {
    background-color: #fff;
      width: 40%;
      margin: 60px auto;
      /* margin: 5rem auto; */
      padding: 26px;
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  }
  .errors {
    color: red;
    font-size: 14px;
    font-style: italic;
    text-align: left;
    margin-top: -12px;
    margin-bottom: 15px;
  }
  .headImage {
    text-align: center;
  }
  .headingTextFirst {
    font-size: 24px;
    font-weight: 700;
    padding: 15px 0 12px 0;
  }
  .headingTextSecond {
    font-size: 18px;
    /* font-weight: 600; */
  }
  .headingTextThird {
    font-size: 16px;
    padding: 35px 0 15px 0;
    color: gray;
  }
  .otpsent{
    color: #b1b1b1;
  }
  .progressBar {
    margin: auto;
    width: 60%;
  }
  .inputContainer {
    width: 80%;
    /* margin: 3rem auto; */
    margin: 45px auto 30px;
  }
  .btnNext {
    width: 50%;
    margin: 30px auto 10px;

  }
  .sasOneText {
    padding: 25px 0px 0px;
  }
  .sendOtp{
    width: 32%;
    margin: auto;
  }
  .outer_otp_div {
    width: 190px;
    overflow: hidden;
    position: relative;
    margin:20px auto;
  }
  .otp_div {
    position: sticky;
    left: 0;
  }
  .successMessage{
    color: #f0f0f0;
    background-color: #ef5d25;
    width: 180px;
    text-align: center;
    margin: auto;
    margin-top: 10px;
    padding: 10px
    }
  .otp_input {
    padding-left: 10px !important;
    letter-spacing: 33px;
    font-size: 30px !important;
    border: 0 !important;
    background-image: linear-gradient(
      270deg,
      #616161 70%,
      hsla(0, 0%, 100%, 0) 0
    );
    background-position: bottom;
    background-size: 50px 2px;
    background-repeat: repeat-x;
    background-position-x: 35px;
    width: 220px !important;
    min-width: 220px;
    border-radius: 0;
    outline: none;
    margin: 20px 0px;
  }
  .resendCode{
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    padding-bottom: 2px;
    border-bottom: 1px solid #004ebd;
    width: 95px;
    color: #004ebd;
    margin:14px auto 20px;
  }
  .resendCodeLoader{
text-align: center;
display: flex;
justify-content: center;
    width: 95px;
    margin:14px auto 20px;
  }
  .resendCode:hover{
    color: #ef5d25;
    border-color: #ef5d25;
  }
  @media screen and (max-width: 1023px) {
    .inputSectionContainer {
      /* border: 1px solid grey; */
      width: 100%;
      /* margin: 4rem auto; */
      padding: 0px;
      box-shadow: none;
    }
    .progressBar {
     
      width: 75%;
    }
    .btnNext {
      width: 75%;
    }
    .inputContainer {
      width: 98%;
      /* margin: 3rem auto 2rem; */
    }
  }
  