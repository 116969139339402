.mainBoxLoginOuter {
  box-sizing: border-box;
  background-color: #fff;
}

.centerBox {
margin: 4% 8% 15% 8%;
}

.loginSubtitle {
font-size: 20px;
font-weight: 600;
color: #413f42;
margin-top: 6px;
}

.loginTitle {
font-size: 30px;
font-weight: 700;
color: #191919;
}

.loginButton {
text-align: center;
margin: 8% 10% 5% auto;
}

.forgotPass {
color: #413f42b9;
font-weight: 500;
font-size: 18px;
margin-top: 10px;
cursor: pointer;
text-align: center;
}

.inputsBox {
margin: 18px 0px 45px 0px;
}

.sasoneText {
text-align: center;
}

.onepostTextBox {
display: flex;
}

.topBoxOnePost {
background-color: #163b64;
padding: 6% 8% 5% 8%;
box-sizing: border-box;
}

.onePostText{
  font-weight: 700;
  font-size: 25px;
  color: #ffff;
}

.onePostFirstSubtitle{
  font-size: 22px;
  font-weight: 600;
  color: rgba(250, 235, 215, 0.918);
}

.onePostSecSubtitle{
  font-size: 18px;
  color: #FDFAF6;
}

.logo{
  margin-right: 6px;
}

.errors {
color: red;
font-size: 14px;
font-style: italic;
margin-top: -12px;
}

.onePostSubtitle{
width: 80%;
}


.webBoxLoginOuter{
  display: flex;
}

.blueBoxOuter{
text-align: center;
}

.flexBox{
margin: 2% auto;
width: 58%;
display: flex;
justify-content: center;
align-items: center;
}
.signupLink{
  color: orangered;
  font-weight: 500;
  font-size: 18px;
}
.centerBoxweb,.sasoneTextweb{
width: 80%;
margin: auto;
margin-bottom: 40px;
}

.onepostTextBoxweb{
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 20px;
}

.blueBoxOuterweb{
background-color: #163b64;
padding: 50px 10px;
width: 50%;
color: #ffff;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.onePostFirstSubtitleweb{
text-align: center;

}

.onePostSecSubtitleweb{
text-align: center;
padding: 25px 10px 10px 10px;
}

.inBoxSpaceweb{
width: 50%;
padding-top: 5%;
display: flex;
flex-direction: column;
justify-content: space-between;
background-color: #ffff;
}

.onePostFirstSubtitleweb{
font-size: 25px;
color: rgb(250, 235, 215);
}

.imgBox{
margin: 40px auto;
}

.inputsBoxweb{
margin: 8% 10% 20% auto;
}

.sasoneTextweb{
text-align: center;
margin: 20px auto 40px auto;  
}