
.mainContainer {
  display: flex;
  background-color: #ebebeb;
}

.innerContainer{
  width: 100%;
  min-height: 100vh;
}
.mbileWrapper{
  background-color: #fff;
}