.mainContainer {
    /* width: 100%; */
    padding: 20px;
  }
  .logoContainer {
    display: flex;
    padding-top: 20px;
    justify-content: center;
  }
  .errors {
    color: red;
    font-size: 14px;
    font-style: italic;
    text-align: left;
    margin-top: -12px;
    margin-bottom: 15px;
  }
  .inputSectionContainer {
    /* border: 1px solid grey; */
    width: 40%;
    background-color: #fff;
    margin: 60px auto;
    padding: 35px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  }
  .headImage {
    text-align: center;
  }
  .headingTextFirst {
    font-size: 24px;
    font-weight: 700;
    padding: 15px 0 12px 0;
  }
  .headingTextSecond {
    font-size: 18px;
    font-weight: 600;
  }
  .headingTextThird {
    font-size: 16px;
    padding: 25px 0 15px 0;
    color: gray;
  }
  .progressBar {
    margin: auto;
    width: 60%;
  }
  .inputContainer {
    width: 46%;
    margin: 45px auto 0;
  }
  .inputContainerSection {
    width: 77%;
    margin: auto;
  }
  .btnNext {
    width: 50%;
    margin: 30px auto 10px;

  }
  .sasOneText {
    padding: 50px 0px 20px;
  }
  .inputSectionFlex {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
  }
  @media screen and (max-width: 1023px) {
    .inputSectionContainer {
      /* border: 1px solid grey; */
      width: 100%;
      /* margin: 4rem auto 0; */
      padding: 0px;
      box-shadow: none;
    }
    .progressBar {
      width: 75%;
      margin-bottom: 45px;
    }
    .btnNext {
      width: 75%;
    }
    .inputContainer {
      width: 98%;
      margin:0 auto 0;
    }
    .inputSectionFlex {
      width: 100%;
    }
    .inputContainerSection {
      width: 98%;
    }
    .inputSectionFlex {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        margin: auto;
      }
  }
  