.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
}

.modal {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  width: 350px;
  z-index: 70;
  padding: 10px;
  border-radius: 5px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* align-items: center; */
}

.closeIcon {
  text-align: right;
  /* width: 98%; */
  color: #fff;
  cursor: pointer;
  padding-top: 0;
}
.backIcon {
  cursor: pointer;
  height: 25px;
}
.button {
  width: 70px;
  color: #1778f2;
  padding: 2px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.modalContainerWidth {
  background-color: white;
  margin: auto;
  position: relative;
  font-weight: 500;
  width: 602px;
  padding: 20px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
  z-index: 2;

  padding-bottom: 30px;
}
.mainContainer {
  padding: 0px 30px;
}

.heading {
  font-size: 20px;
  /* font-weight: 700; */
  padding: 5px 10px 5px 10px;
  color: #1d5c63;
}

.line {
  width: 40px;
  height: 2px;
  background-color: black;
}

.modBox {
  background-color: white;
  /* margin: auto; */
  position: relative;
  z-index: 30;
  width: 100%;
  font-weight: 500;
  padding: 10px 9px 23px 9px;
  border-radius: 5px;
  box-shadow: 5px 5px 25px 2px #888888;
}

.signinBtn {
  display: block;
  /* align-items: center; */
  /* justify-content: flex-start; */
}

.btnLogo {
  margin-left: 20px;
  width: 80%;
}
.accsize {
  display: flex;
  margin: 20px auto;
  color: #576f72;
  margin-top: 18px;
}

.fbBtn,
.lkdBtn,
.igBtn,
.twitterBtn {
  padding: 6px 20px 6px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  height: 25px;
  border-radius: 5px;
  margin: 20px 20px 5px 20px;
}

.fbBtn:hover {
  color: #3b5998;
  background-color: #fff;
  border: 1px solid #3b5998;
}

.lkdBtn:hover {
  color: #0072b1;
  border: 1px solid #0072b1;
  background-color: #fff;
}
.twitterBtn:hover {
  color: #1da1f2;
  border: 1px solid #1da1f2;
  background-color: white;
}
.igBtn:hover {
  color: #dc2743;
  border: 1px solid #dc2743;
  background: #fff;
}

.lkdBtn {
  background-color: #0072b1;
  border: 1px solid #0072b1;
}
.twitterBtn {
  background-color: #1da1f2;
  border: 1px solid #1da1f2;
}
.fbBtn {
  background-color: #3b5998;
  border: 1px solid #3b5998;
}

.igBtn {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  border: 1px solid transparent;
}

.overlay {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  animation-name: example;
  animation-duration: 0.4s;
}
.closeIconModal {
  cursor: pointer;
  border-radius: 4px;
}
.closeIconModal:hover {
  background-color: #fcd6c7;
  color: #fff;
}
.popBox {
  width: 300px;
  min-height: 120px;
  padding: 10px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 3;
  animation-name: example;
  animation-duration: 1s;
}
.containerPopBox::-webkit-scrollbar {
  display: none;
}
.containerPopBox {
  width: 35%;
  overflow: scroll;
  box-sizing: border-box;
  background-color: white;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.closeIcon {
  text-align: end;
}
.middleSection {
  width: 100%;
}
.closeButtonDiv {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.closeButton {
  border: none;
  outline: none;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #f55b20;
  color: white;
  cursor: pointer;
}
.modalBtn {
  font-size: 14px;
  padding: 5px 30px;
}
.dualButtonContainer {
  text-align: center;
  display: flex;
  width: 50%;
  margin: auto;
  margin-top: 25px;
  justify-content: space-between;
}
.imageWrapperDisable {
  width: 50px;
  height: 50px;
  margin: 8px;
  cursor: not-allowed;
  opacity: 0.5;
}
.failedtext{
  color: red;
}
.successtext{
color: gray;
}
.closeheadingWrapper {
  display: flex;
  justify-content: space-between;
  background-color: #ef5d25;
  align-items: center;
  padding:5px 10px;
}
.RemoveContactText {
  padding: 10px 20px;
  font-size: 18px;
  padding-bottom: 20px;
}
.RemoveContactTextOrange {
  padding: 30px 40px;
  padding-top: 25px;
  font-size: 18px;
}
.modalHeading {
  font-size: 19px;
  margin-left: 10px;
  /* font-weight: 700; */
  color: #fff;
  width: 200px;
}

.modalIn {
  animation-name: modalIn;
  animation-duration: 1s;
}

.textAlignCenter {
  text-align: center;
}
.popBoxPadding {
  padding: 20px;
}
.modalFooter {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  /* background-color: #f2e7e1; */
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0 32px;
  /* padding-bottom: 20px; */
}
.textButton {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.rightSideBtnWrapper {
  display: flex;
}
.okbutton {
  background-color: #ef5d25;
  border: none;
  border: 1px solid #ef5d25;
  border-radius: 4px;
  padding: 6px 25px;
  font-size: 16px;
  color: white;
  margin-bottom: 10px;
  cursor: pointer;
}

.okbutton:hover {
  color: #ef5d25;
  border: 1px solid #ef5d25;
  background-color: white;
}
.textButton:hover {
  background-color: #f29069;
  color: white;
}

.disable {
  opacity: 0.5;
  pointer-events: none;
}
@keyframes modalIn {
  0% {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
  40% {
    opacity: 0.9;
    transform: translate(-50%, -47%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.modalFooterOrange {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0 20px;
}
.imagesDiv {
  width: 70%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: 20px;
}
.imageWrapper {
  width: 50px;
  height: 50px;
  margin: 8px;
  cursor: pointer;
}
.heading {
  text-align: center;
}
@media screen and (max-width: 1023px) {
  .modal {
    width: 80%;
  }
  .containerPopBox {
    width: 90%;
  }
  .heading {
    padding: 5px 10px 5px 10px;
  }

  .modalContainerWidth {
    margin-left: 20px;
    margin-right: 20px;
  }
  .mainContainer {
    padding: 0px 15px;
  }
}
