.mainContainer {
  /* width: 100%; */
  padding: 12px;
}
.logoContainer {
  display: flex;
  padding-top: 20px;
  justify-content: center;
}
.inputSectionContainer {
   background-color: #fff;
   width: 40%;
   margin: 60px auto;
   padding: 26px;
   box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.errors {
  color: red;
  font-size: 14px;
  font-style: italic;
  text-align: left;
  margin-top: -12px;
  margin-bottom: 15px;
}
.headImage {
  text-align: center;
}
.headingTextFirst {
  font-size: 24px;
  font-weight: 700;
  padding: 15px 0 12px 0;
}
.headingTextSecond {
  font-size: 18px;
  font-weight: 600;
}
.headingTextThird {
  font-size: 16px;
  padding: 25px 0 15px 0;
  color: gray;
}
.progressBar {
  margin: auto;
  width: 60%;
}
.inputContainer {
  width: 46%;
  margin: 45px auto 0px;
}
.inputContainerSection {
  width: 82%;
  margin: auto;
}
.btnNext {
  width: 50%;
  margin: 30px auto 10px;
}
.sasOneText {
  padding: 50px 0px 20px;
}
.inputSectionFlex {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: auto;
}
@media screen and (max-width: 1023px) {
  .inputSectionContainer {
    /* border: 1px solid grey; */
    width: 100%;
    /* margin: 4rem auto 0; */
    padding: 0px;
    box-shadow: none;
  }
  .progressBar {
    width: 75%;
  }
  .btnNext {
    width: 75%;
  }
  .inputContainer {
    width: 48%;
    /* margin: 3rem auto 0; */
  }
  .inputSectionFlex {
    width: 98%;
  }
  .inputContainerSection {
    width: 96%;
  }
}
