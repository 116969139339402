.cardBox{
    background-color: white;
    border-radius: 10px;
    border: 2px solid #dddddd;
    width: 280px;
    margin: 10px;
}
.cardBoxFeed{
    background-color: white;
    border-radius: 10px;
    border: 1px solid #dddddd;
    width: 280px;
    text-decoration: none;
    color: black;
    margin: 10px;
}
.cardBoxFeed:hover{
    border: 1px solid #ef5122;
}
.heading{
    font-weight: bold;
    margin: 0 0 15px 10px;
}
.reach{
    margin: 0 15px;
    margin-bottom: 20px;
    color: gray;
}
.impression{
    margin-left: 10px;
}
.headerOuterBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 10px;
    padding-bottom: 0px;
}

.headerFirstBox{
    display: flex;
    align-items: center;
}

.userTitle{
    font-weight: 600;
}

.profImgBox{
    border-radius: 50%;
    margin-right: 8px;
}

.line{
    border-bottom: 1px solid #bbb;
    margin-bottom: 2px;
}

.lineBottom{
    border-top: 1px solid #bbb;
}
.postImgContainer{
    /* width: 250px;
    height: 150px; */
    text-align: center;
}
.userIcon {
    height: 40px;
    width: 40px;
    border-radius: 50px;
    margin: 3px 10px 0 2px;
  }
  .postImg{
    text-align: center;
    height: 250px;
    padding: 0px 15px 0px 15px;
    /* width: 250px; */
}


.postImginner{
    /* max-width: 100%;
    max-height: 100%; */
    object-fit: contain;
    margin-top: 10px
}
.postImg:hover{
    object-fit: contain;
}

.postImgFirst{
    object-fit: cover;
    text-align: center;
    width: 90%;
    padding: 0px 12px;
    height: 200px;
}
.postImgFirst:hover{
    object-fit: contain;
}

.likesCountBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    color: #423f3eea;
}

.heart{
    margin-right: 18px;
    position: relative;
}

.like{
    position: absolute;
    padding-left: 12px;
}

.iconLikesBox{
    display: flex;
    align-items: center;
}

.bottomTextBox{
    margin-left: 60px;
    margin-bottom: 20px;
    color: #423f3eea;
}

.bottomIconBox{
    display: flex;
    align-items: center;
}

.iconBottom{
    margin-right: 8px;
}

.userSubTitle{
    color: #73777B;
}

.caption{
    /* white-space: pre-wrap; */
    text-align: left;
    margin: 0px 0px 10px 15px;

}

/* .iconBottom{
    font-size: 10px;
} */