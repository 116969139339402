.button {
  background-color: #ef5d25;
  color: white;
  border: none;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding: 5px 22px;
  border: 1px solid white;
  cursor: pointer;
  margin: 20px 0;
}
.button:hover {
  background-color: white;
  color: #ef5d25;
  border: 1px solid #ef5d25;
  transition: ease-in-out;
}
.smallButton {
  background-color: white;
  color: #ef5d25;
  border: none;
  outline: none;
  font-size: 16px;
  border-radius: 3px;
  font-weight: 500;
  padding: 7px 25px;
  border: 1px solid #ef5d25;
  cursor: pointer;
  /* margin: 30px 0; */
}
.whiteButton {
  background-color: white;
  color: #ef5d25;
  border: none;
  outline: none;
  font-size: 18px;
  border-radius: 3px;
  font-weight: 500;
  padding: 7px 25px;
  border: 1px solid #ef5d25;
  cursor: pointer;
  /* margin: 20px 0; */
}
.smallButton:hover {
  color: #ef5d25;
  background-color: #ceecf8b7;
  border: 1px solid #ef5d25;
  transition: ease-in-out;
}
.whiteButton:hover {
  color: white;
  background-color: #187498;
  border: 1px solid #187498;
}
.greenFilled{
  background-color: green;
  color: white;
  width: 100%;
  border-radius: 5px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 25px;
  border: 1px solid green;
  cursor: pointer;
}
.smallButtonOrange {
  background-color: #ef5d25;
  color: white;
  width: 100%;
  border-radius: 25px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  padding: 7px 25px;
  border: 1px solid #ef5d25;
  cursor: pointer;
}

.smallButtonOrange:hover {
  background-color: #fff;
  color: #ef5d25;
  border: 1px solid #ef5d25;
  transition: ease-in-out;
}

.orangeFilled {
  background-color: #ef5122;
  color: white;
  width: 100%;
  border-radius: 5px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 25px;
  border: 1px solid #ef5d25;
  cursor: pointer;
}
.orangeFilledD {
  background-color: #ef5122;
  color: white;
  width: 100%;
  border-radius: 5px;
  border: none;
  outline: none;
  display: flex;
  opacity: 0.5;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 25px;
  border: 1px solid #ef5d25;
  cursor: not-allowed;
}
.orangeFilled:hover {
  background-color: #fff;
  color: #ef5d25;
  border: 1px solid #ef5d25;
  transition: ease-in-out;
}
.greenFilled:hover{
  background-color: #fff;
  color: green;
  border: 1px solid green;
  transition: ease-in-out;
}
.orangeOutlined {
  background-color: #ffffff;
  color: #ef5122;
  width: 100%;
  border-radius: 5px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  font-size: 14px;
  padding: 5px 15px;
  border: 1px solid #ef5d25;
  cursor: pointer;
}
.orangeOutlinedD {
  background-color: #ffffff;
  color: #ef5122;
  width: 100%;
  border-radius: 5px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  font-size: 14px;
  padding: 5px 15px;
  border: 1px solid #ef5d25;
  cursor: not-allowed;
}

.orangeOutlined:hover {
  background-color: #ef5d25;
  color: white;
  border: 1px solid transparent;
  transition: ease-in-out;
}
