.inputArea {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  margin: 15px 0;
  font-size: 18px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* width: 100%; */
}
.inputArea::placeholder {
  font-size: 14px;
}

.inputAreaDate {
  outline: none;
  border: 1px solid #ef5122;
  margin: 15px 0;
  font-size: 20px;
  display: flex;
  flex-direction: row-reverse;
  border-radius: 5px;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.inputAreaDate::-webkit-calendar-picker-indicator{
  border-right: 1px solid #ef5122;
  padding: 10px;
  margin-right: 4px;
}

.inputAreaDate:hover{
  background-color: #ef522217;
}
.inputArea:focus {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: white;

  border: 1px solid #ece9e9;
}
.inputAreaDropdown {
  padding: 7px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  margin: 15px 0;
  height: 45px;
  font-size: 18px;
  width: 100%;
}
.redioButton{
  cursor: pointer;
}
.uploadInput {
  padding: 10px;
  
  outline: none;
  border: 1px solid #ece9e9;
  margin: 15px 0;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  cursor: pointer;
  /* flex-direction: column; */
}
.inputAreaMulti {
  padding: 10px 10px;
  outline: none;
  border: 1px solid #ece9e9;
  margin: 0 0 15px 0;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
}
.checkboxInput {
  /* margin: 5px 10px;
  margin-left: 0; */
  cursor: pointer;
}
.dualInput:focus {
  background-color: white;
}

.dualInputDiv {
  display: flex;
}
.formContainer {
  display: flex;
  flex-direction: column;
}
.checkboxContainer {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.input_fields_mobile {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.input_fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ece9e9;
  margin: 15px 0;
  width: 100%;
  font-size: 18px;
}

.inputArea_withIcon {
  padding: 10px 12px;
  width: 90%;
  border: none;
  font-size: 18px;
  outline: none;
}

.inputArea_withIcon::placeholder {
  font-size: 14px;
}
.inputArea_withIconFocus {
  background-color: white;
}
.eyeIcon {
  margin-right: 10px;
  height: 23px;
  width: 23px;
  cursor: pointer;
}
.dropDownInput {
  padding: 0 4px;
  outline: none;
  margin-left: 10px;
  cursor: pointer;
}
.label {
  font-size: 17px;
  font-weight: 600;
  color: #162020;
  margin-bottom: 5px;
  text-align: left;
  font-weight: bold;
}

.dropDownLabel {
  font-weight: 600;
}
.dropDownInputContainer {
  display: flex;
  align-items: center;
}
.radioDiv {
  margin: 0 5px;
}
.radioInput {
  margin: 9px 0 5px 0;
  cursor: pointer;
}

.uploadIcon{
  height: 70px;
  width: 70px;
  margin-top: 15px;
}
.postImg{
  height: 100px;
  width: 100px;
}


.postImginner{
  /* max-width: 100%;
  max-height: 100%; */
  object-fit: contain;
}
.uploadPlaceholder{
  color: #413f42;
  font-weight: bold;
  font-size: 18px;
  margin-left: 30px;
  margin-top: 10px;
}
.input_fields,.input_fieldsLogin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ece9e9;
  margin: 15px 0;
  width: 100%;
  font-size: 18px;
}
.inputAreaLoginType{
  padding: 8px 10px;
  border-radius: 8px;
  background-color: #E3F2FC;
  outline: none;
  border: 1px solid #ece9e9;
  margin: 15px 0;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
}

.inputAreaLoginType::placeholder{
  font-size: 14px;
}
.inputAreaLoginType:focus {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  border: 1px solid #ece9e9;
}
.input_fieldsLogin {
  border-radius: 10px;
  background-color: #E3F2FC;
  }
  .inputArea_withIconLogin{
    padding: 8px 12px;
    background-color: #E3F2FC;
    width: 90%;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    outline: none;
  }
  .inputArea_withIconLogin::placeholder {
    font-size: 14px;
    background-color: #E3F2FC;
  }
  .inputArea_withIconFocusLogin {
    background-color: #E3F2FC;
  }  