.messageBox{
    height: 65vh;
    width: 60%;
    margin: 60px auto;
    display: flex;
    background-color: white;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 30px;
}
.buttonText{
    color: #163d66;
}