.containerInner {
    background-color: #e1eeda;
    padding: 45px 30px;
    border-radius: 6px;
    height: fit-content;
  }
  .previewPostDummy {
    height: 150px;
    width: 100%;
    object-fit: contain;
    margin: 10px 0;
  }
  .postContainer {
    background-color: white;
    padding: 20px 10px;
    border-radius: 8px;
    border: 2px solid #dddddd;
    width: 260px;
    margin: auto;
  }
  .previewPost {

    object-fit: contain;
  
    margin: 10px 0;
  }
  .postHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .postHeaderUsername {
    display: flex;
    align-items: center;
  }
  .userIcon {
    height: 40px;
    width: 40px;
    border-radius: 50px;
    margin: 3px 10px 0 2px;
  }
  .favIcon {
    height: 24px;
    width: 24px;
    color: #000;
    margin-left: 8px;
  }
  .likesContainer {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 7px 0 4px 8px;
  }
  .favIconSmall {
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-right: 6px;
  }
  .caption {
    white-space: normal;
    text-align: left;
    /* width: 250px; */

    font-weight: normal;
    margin-left: 8px;
  }
  .hashTags {
    font-size: 15px;
    margin-left: 8px;
  }
  .heading {
    font-weight: bold;
    margin: 0 0 15px 10px;
  }
  
  .line{
      border-bottom: 1px solid #bbb;
      margin-bottom: 8px;
  }
  
  .userTitle {
    font-weight: 600;
    width: 190px;
    padding-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .userName {
    font-weight: 600;
    width: 252px;
    padding-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .postImg{
    text-align: center;
    height: 250px;
    padding: 0px 15px 0px 15px;
    /* width: 250px; */
}
.postImginner{
    /* max-width: 100%;
    max-height: 100%; */
    object-fit: contain;
    padding: 0px 10px;
}

.postImginnernew{
  object-fit: contain;
}