@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  img, video {
    height: inherit;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* .tox-tinymce{
  border-radius: 0 !important;
  border: 1px solid rgb(202, 202, 202) !important;
 
}
.tox-statusbar{
  display: none !important;
}
p{
  line-height: 0;
}
.mce-content-body {
  font-size: 18px !important;
}
.mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before{
  font-size: 18px !important;
  font-weight: 600 !important;
} */