.mainContainer {
  border: 1px solid lightgrey;
  border-radius: 12px;
  width: 100%;
}
.bgColorFirst {
  background-color: lightgrey;
  width: 35%;
  background-image: linear-gradient(45deg, #18bdfb, #9c2ade);
  color: #fff;
  border-radius: 12px;
  font-size: 10px;
}
.bgColorSecond {
  background-color: lightgrey;
  width: 65%;
  background-image: linear-gradient(45deg, #18bdfb, #9c2ade, #ef6156);
  color: #fff;
  border-radius: 12px;
  font-size: 10px;
}
.bgColorThird {
  background-color: lightgrey;
  width: 100%;
  background-image: linear-gradient(45deg, #18bdfb, #9c2ade, #ef6156, #eb1f5e);
  color: #fff;
  border-radius: 12px;
  font-size: 10px;
  border: none;
}
