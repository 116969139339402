.displayFrame {
  border-radius: 10px;
  width: 300px;
  white-space: nowrap;
}

.alignBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  overflow: hidden;
  align-items: center;
}

.carouselItem {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  transition: all 500ms ease-out;
}

.fastAnimation {
  transition: all 50ms linear;
}

.carouselItem img {
  /* background-color: none; */
}

.navigation {
  display: flex;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  margin: auto;
}

.positionIndicator {
  box-sizing: border-box;
  width: 19px;
  height: 19px;
  border: 1px solid #aabbcc;
  border-radius: 50%;
  margin: 2px;
}

.positionIndicator:hover {
  background: #ccddee;
}

.positionIndicator:active {
  background-color: #206ebd;
}

.currentPosition {
  border: 1px solid #778899;
  background: #aabbcc;
}

@media (max-width: 600px) {
  .positionIndicator {
    width: 10px;
    height: 10px;
  }
}
